import React from "react";
import { Route, Routes } from "react-router-dom";
import Overview from "./Data.Overview";

type Props = {};

const Data = (props: Props) => {
  return (
    <Routes>
      <Route path="/" element={<Overview />} />
    </Routes>
  );
};

export default Data;
