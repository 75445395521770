import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import Form from "../Form/Form";
import Block from "../Common/Block";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../Form/Button";
import BlockHeader from "../Common/BlockHeader";
import MaterialService from "../../services/material.service";

const MaterialFrom = () => {
  const [error, setError] = useState();
  const [succes, setSucces] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();

  const formSchema = Yup.object().shape({
    name: Yup.string().required("Naam is verplicht"),
    unit: Yup.string().required("Eenheid is verplicht"),
    price: Yup.number()
      .transform((_, val) => {
        let newVal = val.replace(",", ".");
        if (newVal) newVal = Number(newVal);
        return typeof newVal == "number" ? newVal : null;
      })
      .typeError("Prijs moet een nummer zijn en is verplicht")
      .required("Prijs is verplicht"),
  });
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  const fromInputs = [
    {
      placeholder: "Naam",
      error: errors.name?.message,
      type: "text",
      ...register("name"),
    },
    {
      placeholder: "Eenheid",
      error: errors.unit?.message,
      type: "text",
      ...register("unit"),
    },
    {
      placeholder: "Prijs",
      error: errors.price?.message,
      type: "text",
      ...register("price"),
    },
  ];

  const handleAdd = (data) => {
    setLoading(true);

    MaterialService.addMaterial(data.name, data.price, data.unit).then(
      (response) => {
        if (response.status === 200) {
          setSucces("Materiaal is toegevoegd");
          setError();
          setLoading(false);
          reset();
        }
      },
      (error) => {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
          setSucces();
          setLoading(false);
        }
      }
    );
  };

  const handleUpdate = (data) => {
    setLoading(true);

    MaterialService.updateMaterial(id, data.name, data.price, data.unit).then(
      (response) => {
        if (response.status === 200) {
          navigate(-1);
        }
      },
      (error) => {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
          setSucces();
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (id) {
      MaterialService.getMaterial(id).then((response) => {
        if (response.data) {
          let data = response.data;
          data.price = data.price.toString().replace(".", ",");
          reset(data);
        }
      });
    }
  }, [id, reset]);

  const buttons = [
    <Button text={"Terug"} secondary={true} onClick={() => navigate(-1)} />,
  ];

  return (
    <div className="col-md-12">
      <Block>
        <BlockHeader buttons={buttons}>
          Materiaal {id ? "aanpassen" : "toevoegen"}
        </BlockHeader>
        <Form
          fromInputs={fromInputs}
          submitText={id ? "Aanpassen" : "Toevoegen"}
          loading={loading}
          onSubmit={handleSubmit(id ? handleUpdate : handleAdd)}
          error={error}
          succes={succes}
        />
      </Block>
    </div>
  );
};

export default MaterialFrom;
