import React, { useState, useEffect } from "react";
import Block from "../Common/Block";
import BlockHeader from "../Common/BlockHeader";
import { useParams, useNavigate } from "react-router-dom";
import MoldService from "../../services/mold.service";
import Button from "../Form/Button";
import { ClipLoader } from "react-spinners";
import moment from "moment/moment";
import MaterialService from "../../services/material.service";
import Functions from "../../common/Functions";
import Table from "../Common/Table.js/Table";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import MaterialRegistrationService from "../../services/material-registration.service";
import DeleteModal from "../Common/DeleteModal";
import MoldHourService from "../../services/moldHour.service";
import AuthService from "../../services/auth.service";

const View = (props) => {
  const [mold, setMold] = useState();
  const [materials, setMaterials] = useState([]);
  const [moldHours, setMoldHours] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const [editMaterialRegistration, setEditMaterialRegistration] = useState();
  const [showAddMoldHourModal, setShowAddMoldHourModal] = useState(false);
  const [editMoldHour, setEditMoldHour] = useState();
  const [moldPrice, setMoldPrice] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const currentUser = AuthService.getCurrentUser();

  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    setLoading(true);

    if (id) {
      MoldService.getMold(id).then((response) => {
        if (response.data) {
          setMold(response.data);
          let totalPrice = 0;

          if (!response.data.finished) {
            const dataMaterials = response.data.materials.map((material) => {
              totalPrice += material.amount * material.material.price;
              return {
                ...material.material,
                amount: material.amount,
                id: material.id,
                materialId: material.material.id,
              };
            });
            setMaterials(dataMaterials);
          } else {
            const dataMaterials = response.data.finishedMaterials.map((material) => {
              totalPrice += material.amount * material.price;
              return {
                ...material,
                materialId: material.id,
              };
            });
            setMaterials(dataMaterials);
          }


          setMoldHours(response.data.moldHours);
          setMoldPrice(totalPrice);
          setLoading(false);
        }
      });
    }
  }, [id]);

  const buttons = [
    <Button text={"Terug"} secondary={true} onClick={() => navigate(-1)} />,
    <Button
      text={"Bewerken"}
      className={"ml-2"}
      onClick={() => navigate("/mold/form/" + id)}
    />,
  ];

  const openAddMaterialModal = (id) => {
    setEditMaterialRegistration(id);

    setShowAddMaterialModal(true);
  };

  const closeAddMaterialModal = () => {
    setShowAddMaterialModal(false);
  };

  const openAddMoldHourModal = (id) => {
    setEditMoldHour(id);

    setShowAddMoldHourModal(true);
  };

  const closeAddMoldHourModal = () => {
    setShowAddMoldHourModal(false);
  };

  const onDelete = (id) => {
    MoldService.deleteMold(id).then((response) => {
      if (response.status === 200) {
        navigate(-1);
      }
    });
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  return (
    <div className="col-12">
      {showAddMaterialModal && (
        <AddMaterialModal
          isAdmin={props.isAdmin}
          onClose={closeAddMaterialModal}
          materials={materials}
          setMaterials={setMaterials}
          moldId={id}
          editMaterialRegistration={editMaterialRegistration}
          setMoldPrice={setMoldPrice}
          moldPrice={moldPrice}
        />
      )}
      {showAddMoldHourModal && (
        <AddMoldHourModal
          onClose={closeAddMoldHourModal}
          moldHours={moldHours}
          setMoldHours={setMoldHours}
          moldId={id}
          mold={mold}
          editMoldHour={editMoldHour}
        />
      )}
      <Block>
        <BlockHeader buttons={buttons}>{mold?.name}</BlockHeader>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center my-4">
            <ClipLoader size={35} color={"#ff2400"} />
          </div>
        ) : (
          <div className="row">
            <DeleteModal
              show={showDeleteModal}
              title={"Mal verwijderen"}
              onSubmit={() => onDelete(mold.id)}
              onClose={toggleDeleteModal}
            >
              Wil je de mal verwijderen?
            </DeleteModal>
            <div className="col-12 col-md-6 mb-3">
              <div className="d-flex justify-content-between mb-3 align-items-center">
                <h4 className="mb-0">Informatie</h4>
                <div className="d-flex">
                  <Button
                    text={"Verwijderen"}
                    className={"mr-2"}
                    onClick={toggleDeleteModal}
                    secondary={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4">Naam:</div>
                <div className="col">{mold.name}</div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4">Beschrijving:</div>
                <div className="col">{mold.description}</div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4">Malnummer:</div>
                <div className="col">{mold.moldNumber}</div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4">Datum:</div>
                <div className="col">
                  {moment(mold.date).format("DD-MM-YYYY")}
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4">Klaar:</div>
                <div className="col">{mold.finished ? "Ja" : "Nee"}</div>
              </div>
              <div className="row">
                <div className="col-6 col-md-4">Laatst aangepast:</div>
                <div className="col first-uppercase">
                  {mold.lastModifiedBy.username}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <MaterialTable
                loading={loading}
                materials={materials}
                setMaterials={setMaterials}
                openAddMaterialModal={openAddMaterialModal}
                isAdmin={props.isAdmin}
                setMoldPrice={setMoldPrice}
                moldPrice={moldPrice}
                mold={mold}
              />
              {props.isAdmin && (
                <div className="d-flex justify-content-end">
                  <span className="mr-2">Totaalprijs:</span>
                  <span className="font-weight-bold">
                    {Functions.euroFormat(moldPrice)}
                  </span>
                </div>
              )}
            </div>
            <div className="col-12 col-md-6">
              <MoldHourTable
                loading={loading}
                moldHours={moldHours}
                setMoldHours={setMoldHours}
                currentUser={currentUser}
                openAddMoldHourModal={openAddMoldHourModal}
                isAdmin={props.isAdmin}
                mold={mold}
              />
            </div>
          </div>
        )}
      </Block>
    </div>
  );
};

const MoldHourTable = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const onDelete = (id) => {
    MoldHourService.deleteMoldHour(id).then((response) => {
      if (response.status === 200) {
        props.setMoldHours(
          props.moldHours.filter((moldHour) => moldHour.id !== id)
        );

        setShowDeleteModal(false);
      }
    });
  };

  const onRowClick = (row) => {
    props.openAddMoldHourModal(row.id);
  };

  const columns = [
    {
      header: "Datum",
      accessor: "date",
      width: "auto",
    },
    {
      header: "Uren",
      accessor: "hours",
      width: "auto",
    },
    {
      header: "Medewerker",
      accessor: "user",
      width: "auto",
    },
    {
      header: "",
      accessor: "menu",
      width: "40",
      notClickable: true,
    },
  ];

  const toggleDeleteModal = (id) => {
    setShowDeleteModal(!showDeleteModal);
    setDeleteId(id);
  };

  const renderTableValue = (row, column) => {
    if (column.accessor === "menu" && props.mold && !props.mold.finished) {
      if (
        props.currentUser.id === row.user?.id ||
        props.currentUser.roles[0] === "ROLE_ADMIN"
      )
        return (
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className={"fs-20 color-light-red cursor-pointer"}
              onClick={() => toggleDeleteModal(row.id)}
            />
          </div>
        );
      return null;
    }

    if (column.accessor === "date") {
      return (
        <span
          onClick={

            (props.currentUser.id === row.user?.id ||
              props.currentUser.roles[0] === "ROLE_ADMIN") && props.mold && !props.mold.finished

              ? () => onRowClick(row)
              : null
          }
        >
          {moment(row[column.accessor]).format("DD-MM-YYYY")}
        </span>
      );
    }

    if (column.accessor === "user") {
      return (
        <span
          onClick={

            (props.currentUser.id === row.user?.id ||
              props.currentUser.roles[0] === "ROLE_ADMIN") && props.mold && !props.mold.finished

              ? () => onRowClick(row)
              : null
          }
        >
          {row[column.accessor]?.username}
        </span>
      );
    }

    return (
      <span
        onClick={

          (props.currentUser.id === row.user?.id ||
            props.currentUser.roles[0] === "ROLE_ADMIN") && props.mold && !props.mold.finished

            ? () => onRowClick(row)
            : null
        }
      >
        {row[column.accessor]}
      </span>
    );
  };
  let moldTotalHours = props.moldHours.reduce((a, b) => {
    return a + b.hours;
  }, 0);
  let moldHours = props.moldHours.sort((a, b) => {
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        title={"Uren verwijderen"}
        onSubmit={() => onDelete(deleteId)}
        onClose={toggleDeleteModal}
      >
        Wil je deze uren verwijderen?
      </DeleteModal>
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <h4 className="mb-0">Uren</h4>
        {props.mold && !props.mold.finished ? <Button
          text={"Uren toevoegen"}
          onClick={() => props.openAddMoldHourModal(null)}
        /> : null}
      </div>
      <Table
        columns={columns}
        data={moldHours}
        renderTableValue={renderTableValue}
        loading={props.loading}
      />
      {props.currentUser.roles[0] === "ROLE_ADMIN" && (
        <div>
          <span>Totaal uren: </span>
          <span className="font-weight-bold">{moldTotalHours}</span>
        </div>
      )}
    </>
  );
};

const AddMoldHourModal = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      date: moment().format("YYYY-MM-DD"),
    },
  });
  const moldHours = props.moldHours;
  const editMoldHourId = props.editMoldHour;

  useEffect(() => {
    if (editMoldHourId) {
      MoldHourService.getMoldHourById(editMoldHourId).then((response) => {
        if (response.status === 200) {
          const moldHour = response.data;
          reset({
            hours: moldHour.hours,
          });
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [moldHours, editMoldHourId, reset]);

  const onSubmitAdd = (data) => {
    setLoading(true);
    MoldHourService.addMoldHour(props.moldId, data.hours, data.date)
      .then((response) => {
        if (response.status === 200) {
          props.setMoldHours(response.data.moldHours);
          props.onClose();
        }
      })
      .catch((error) => {
        if (error.response.data.error === "mold hour already exists") {
          setError(
            "Er bestaat al een urenregistratie voor deze datum bij deze mal."
          );
          setLoading(false);
          return;
        }
      });
  };

  const onSubmitEdit = (data) => {
    setLoading(true);

    MoldHourService.updateMoldHour(editMoldHourId, data.hours).then(
      (response) => {
        if (response.status === 200) {
          props.setMoldHours(response.data.moldHours);
          props.onClose();
        }
      }
    );
  };

  return (
    <div className="modal">
      <Block>
        <div className="d-flex justify-content-end">
          <Button className={"mb-3"} text={"Sluiten"} onClick={props.onClose} />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center px-3 px-md-5">
          <h4 className="mb-3">
            Mal uren {editMoldHourId ? "aanpassen" : "toevoegen"}
          </h4>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center my-4">
              <ClipLoader size={35} color={"#ff2400"} />
            </div>
          ) : (
            <div>
              {error && (
                <div className="d-flex justify-content-center align-items-center mb-1">
                  <span className="text-danger">{error}</span>
                </div>
              )}
              <form
                onSubmit={handleSubmit(
                  editMoldHourId ? onSubmitEdit : onSubmitAdd
                )}
                className="d-flex flex-column align-items-center"
              >
                <div className="d-flex flex-column mb-3 align-items-center">
                  <div className="mb-2">
                    {!editMoldHourId && (
                      <>
                        <div className="input-error">
                          {errors.date && <span>{errors.date.message}</span>}
                        </div>
                        <input
                          placeholder={"Datum"}
                          type="date"
                          className={"input-text"}
                          {...register("date", {
                            required: "Datum is verplicht!",
                          })}
                        />
                      </>
                    )}
                  </div>
                  <div>
                    <div className="input-error">
                      {errors.hours && <span>{errors.hours.message}</span>}
                    </div>
                    <input
                      placeholder={"Uren"}
                      type="number"
                      step={0.5}
                      className={" input-text"}
                      {...register("hours", {
                        required: "Vul een geldig aantal uren in",
                      })}
                    />
                  </div>
                </div>
                <Button text={"Toevoegen"} loading={loading} />
              </form>
            </div>
          )}
        </div>
      </Block>
    </div>
  );
};

const MaterialTable = (props) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const columns = [
    {
      header: "Naam",
      accessor: "name",
      width: "auto",
    },
    {
      header: "Eenheid",
      accessor: "unit",
      width: "auto",
    },
    {
      header: "Aantal",
      accessor: "amount",
      width: "auto",
    },
    {
      header: "",
      accessor: "menu",
      width: "40",
      notClickable: true,
    },
  ];

  if (props.isAdmin) {
    columns.splice(3, 0, {
      header: "Prijs",
      accessor: "price",
      width: "auto",
    });

    columns.splice(4, 0, {
      header: "Totaal",
      accessor: "totalPrice",
      width: "auto",
    });
  }

  const materials = props.materials.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const onDelete = (id) => {
    MaterialRegistrationService.deleteMaterialRegistration(id).then(
      (response) => {
        if (response.status === 200) {
          const deleted = props.materials.find(
            (material) => material.id === id
          );
          props.setMoldPrice(props.moldPrice - deleted.price * deleted.amount);

          props.setMaterials(
            props.materials.filter((material) => material.id !== id)
          );

          setShowDeleteModal(false);
        }
      }
    );
  };

  const onRowClick = (row) => {
    props.openAddMaterialModal(row.id);
  };

  const toggleDeleteModal = (id) => {
    setShowDeleteModal(!showDeleteModal);
    setDeleteId(id);
  };

  const renderTableValue = (row, column) => {
    if (column.accessor === "menu" && props.mold && !props.mold.finished) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            icon={faTrashAlt}
            className={"fs-20 color-light-red cursor-pointer"}
            onClick={() => toggleDeleteModal(row.id)}
          />
        </div>
      );
    }

    if (column.accessor === "price") {
      return (
        <span>
          {row[column.accessor]
            ? Functions.euroFormat(row[column.accessor])
            : ""}
        </span>
      );
    }

    if (column.accessor === "totalPrice") {
      return <span>{Functions.euroFormat(row.amount * row.price)}</span>;
    }

    return <span>{row[column.accessor]}</span>;
  };

  return (
    <>
      <DeleteModal
        show={showDeleteModal}
        title={"Materiaal verwijderen"}
        onSubmit={() => onDelete(deleteId)}
        onClose={toggleDeleteModal}
      >
        Wil je dit materiaal verwijderen?
      </DeleteModal>
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <h4 className="mb-0">Materialen</h4>
        {props.mold && !props.mold.finished ? <Button
          text={"Materiaal toevoegen"}
          onClick={() => props.openAddMaterialModal(null)}
        /> : null}
      </div>
      <Table
        columns={columns}
        data={materials}
        renderTableValue={renderTableValue}
        loading={props.loading}
        onRowClick={props.mold && !props.mold.finished ? onRowClick : null}
      />
    </>
  );
};

const AddMaterialModal = (props) => {
  const [materials, setMaterials] = useState();
  const [loading, setLoading] = useState(true);
  const [editMaterialRegistration, setEditMaterialRegistration] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const moldMaterials = props.materials;
  const editMaterialRegistrationId = props.editMaterialRegistration;

  useEffect(() => {
    setLoading(true);

    if (editMaterialRegistrationId) {
      MaterialRegistrationService.getMaterialRegistration(
        editMaterialRegistrationId
      ).then((response) => {
        if (response.status === 200) {
          const materialRegistration = response.data;
          setEditMaterialRegistration(materialRegistration);
          reset({
            amount: materialRegistration.amount,
          });
          setLoading(false);
        }
      });
    } else {
      MaterialService.getAllMaterials().then((response) => {
        let materials = response.data;

        moldMaterials.forEach((moldMaterial) => {
          materials = materials.filter(
            (material) => material.id !== moldMaterial.materialId
          );
        });

        setMaterials(materials);
        setLoading(false);
      });
    }
  }, [moldMaterials, editMaterialRegistrationId, reset]);

  const onSubmitAdd = (data) => {
    setLoading(true);
    MaterialRegistrationService.addMaterialRegistration(
      props.moldId,
      data.material,
      data.amount
    ).then((response) => {
      if (response.status === 200) {
        let materialPrice = 0;
        const dataMaterials = response.data.materials.map((material) => {
          if (material.material.id.toString() === data.material)
            materialPrice += material.material.price * material.amount;

          return {
            ...material.material,
            amount: material.amount,
            id: material.id,
            materialId: material.material.id,
          };
        });
        props.setMoldPrice(props.moldPrice + materialPrice);
        props.setMaterials(dataMaterials);
        props.onClose();
      }
    });
  };

  const onSubmitEdit = (data) => {
    setLoading(true);

    MaterialRegistrationService.updateMaterialRegistrationAmount(
      editMaterialRegistrationId,
      data.amount
    ).then((response) => {
      if (response.status === 200) {
        let materialPrice = 0;
        const dataMaterials = response.data.materials.map((material) => {
          if (material.material.id === editMaterialRegistration.material.id)
            materialPrice += material.material.price * material.amount;

          return {
            ...material.material,
            amount: material.amount,
            id: material.id,
            materialId: material.material.id,
          };
        });
        props.setMoldPrice(
          props.moldPrice +
          materialPrice -
          editMaterialRegistration.material.price *
          editMaterialRegistration.amount
        );
        props.setMaterials(dataMaterials);
        props.onClose();
      }
    });
  };

  return (
    <div className="modal">
      <Block>
        <div className="d-flex justify-content-end">
          <Button className={"mb-3"} text={"Sluiten"} onClick={props.onClose} />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center px-3 px-md-5">
          <h4 className="mb-3">
            Materiaal {editMaterialRegistrationId ? "aanpassen" : "toevoegen"}
          </h4>
          {loading ? (
            <div className="d-flex justify-content-center align-items-center my-4">
              <ClipLoader size={35} color={"#ff2400"} />
            </div>
          ) : (
            <div>
              <form
                onSubmit={handleSubmit(
                  editMaterialRegistrationId ? onSubmitEdit : onSubmitAdd
                )}
                className="d-flex flex-column align-items-center"
              >
                <div className="d-flex flex-column flex-md-row mb-3 align-items-center">
                  {editMaterialRegistration ? (
                    <span className="mr-md-2 mb-2 mb-md-0">
                      {editMaterialRegistration.material.name}{" "}
                      {editMaterialRegistration.material.unit}
                    </span>
                  ) : (
                    <div className="mb-2 mb-md-0">
                      <div className="input-error mr-md-2">
                        {errors.material && (
                          <span>{errors.material.message}</span>
                        )}
                      </div>
                      <select
                        className={"input-select mr-md-2"}
                        {...register("material", {
                          required: "Maak een keuze",
                        })}
                      >
                        {materials.map((material) => (
                          <option
                            key={"material-select-option" + material.id}
                            value={material.id}
                          >
                            {material.name} {material.unit}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  <div>
                    <div className="input-error">
                      {errors.amount && <span>{errors.amount.message}</span>}
                    </div>
                    <input
                      placeholder={"Aantal"}
                      type="number"
                      step={0.05}
                      className={" input-text"}
                      {...register("amount", {
                        required: "Vul een geldig aantal in",
                      })}
                    />
                  </div>
                </div>
                <Button text={"Toevoegen"} loading={loading} />
              </form>
            </div>
          )}
        </div>
      </Block>
    </div>
  );
};

export default View;
