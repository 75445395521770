// number to euro format
function euroFormat(number) {
  return formatter.format(number);
}

const formatter = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR",
});

const Functions = {
  euroFormat,
};

export default Functions;
