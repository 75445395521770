import React from "react";
import Button from "../Form/Button";
import Block from "./Block";

const DeleteModal = (props) => {
  if (!props.show) {
    return null;
  }

  return (
    <div className="modal">
      <Block>
        <div className="d-flex justify-content-end">
          <Button className={"mb-3"} text={"Sluiten"} onClick={props.onClose} />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center px-2 px-md-5">
          <h4 className="mb-3">{props.title}</h4>
          <span className="mb-3">{props.children}</span>
          <div className="d-flex">
            <Button
              className={"mr-2"}
              secondary
              text={"Nee"}
              onClick={props.onClose}
            />
            <Button text={"Ja"} onClick={props.onSubmit} />
          </div>
        </div>
      </Block>
    </div>
  );
};

export default DeleteModal;
