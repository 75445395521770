import React from "react";
import Button from "./Button";
import TextInput from "./TextInput";

const Form = (props) => {
  const { error, loading, fromInputs, submitText, succes, ...formProps } =
    props;
  return (
    <form
      {...formProps}
      className={"d-flex flex-column align-items-center " + formProps.className}
    >
      <div className="server-error">{error && <span>{error}</span>}</div>
      <div className="server-success">{succes && <span>{succes}</span>}</div>

      {fromInputs.map((input, index) => {
        input["refs"] = input["ref"];
        delete input["ref"];

        return (
          <TextInput
            key={"input-" + index}
            placeholder={input.placeholder}
            error={input.error}
            type={input.type}
            {...input}
          />
        );
      })}

      <Button text={submitText} className={"mt-2"} loading={loading} />
    </form>
  );
};

export default Form;
