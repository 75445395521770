// import axios from "axios";
import TokenService from "./token.service";
import EventBus from "../common/EventBus";
import { Api } from "../typescript/api/api";

const api = new Api({
  baseURL:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_LOCAL_API_URL
      : process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// const instance = axios.create({
//   baseURL:
//     process.env.NODE_ENV === "development"
//       ? process.env.REACT_APP_LOCAL_API_URL
//       : process.env.REACT_APP_API_URL,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

api.instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    if (err.response?.data?.error === "REFRESH_TOKEN_ERROR") {
      EventBus.dispatch("logout");
      return;
    }

    const originalConfig = err.config;

    if (originalConfig.url !== "/api/auth/signin" && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await api.instance.post("/api/auth/refreshtoken", {
            refreshToken: TokenService.getLocalRefreshToken(),
          });

          const { accessToken } = rs.data;
          TokenService.updateLocalAccessToken(accessToken);

          return api.instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default api;
