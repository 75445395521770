import React from "react";
import "./Table.css";
import ClipLoader from "react-spinners/ClipLoader";

const Table = (props) => {
  const { columns, data, loading } = props;

  let renderTableValue = (row, column) => {
    return <span>{row[column.accessor]}</span>;
  };

  if (!columns) return null;

  if (props.renderTableValue) renderTableValue = props.renderTableValue;

  return (
    <>
      {loading ? (
        <div className="d-flex justify-content-center align-items-center my-4">
          <ClipLoader size={35} color={"#ff2400"} />
        </div>
      ) : (
        <div className="overflow-x-auto">
          <table className="table table-striped table-hover">
            <thead className="thead-dark">
              <tr>
                {columns.map((column, index) => {
                  return (
                    <th
                      key={"head-" + column.header}
                      className=""
                      scope="col"
                      style={{
                        width:
                          column?.width !== "auto"
                            ? column.width + "px"
                            : "auto",
                      }}
                    >
                      {column.header}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="table-body">
              {data?.map((value) => {
                return (
                  <tr key={"value-row-" + value.id} className="">
                    {columns.map((column, index) => {
                      return (
                        <td
                          onClick={
                            props.onRowClick && !column.notClickable
                              ? () => props.onRowClick(value)
                              : null
                          }
                          key={"value-col-" + value + column.accessor + index}
                          className={
                            props.onRowClick && !column.notClickable
                              ? "cursor-pointer"
                              : ""
                          }
                        >
                          {renderTableValue(value, column)}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Table;
