import api from "./api.ts";

const getAllMaterials = () => {
  return api.instance.get("/api/material");
};

const getMaterial = (id) => {
  return api.instance.get(`/api/material/${id}`);
};

const addMaterial = (name, price, unit) => {
  return api.instance.post("/api/material", { name, price, unit });
};

const updateMaterial = (id, name, price, unit) => {
  return api.instance.put(`/api/material/${id}`, { name, price, unit });
};

const deleteMaterial = (id) => {
  return api.instance.delete(`/api/material/${id}`);
};

const MaterialService = {
  getAllMaterials,
  getMaterial,
  addMaterial,
  updateMaterial,
  deleteMaterial,
};

export default MaterialService;
