import api from "./api";

const getAllMaterialRegistrations = () => {
  return api.instance.get("/api/material-registration");
};

const getMaterialRegistration = (id) => {
  return api.instance.get(`/api/material-registration/${id}`);
};

const updateMaterialRegistrationAmount = (id, amount) => {
  return api.instance.put(`/api/material-registration/${id}`, { amount });
};

const addMaterialRegistration = (moldId, materialId, amount) => {
  return api.instance.post(`/api/material-registration/${moldId}/${materialId}`, {
    amount,
  });
};

const deleteMaterialRegistration = (registrationId) => {
  return api.instance.delete(`/api/material-registration/${registrationId}`);
};

const MaterialRegistrationService = {
  getAllMaterialRegistrations,
  getMaterialRegistration,
  updateMaterialRegistrationAmount,
  addMaterialRegistration,
  deleteMaterialRegistration,
};

export default MaterialRegistrationService;
