import React, { useState } from "react";
import "./Toggle.css";

const Toggle = ({ label, toggled, onClick, className }) => {
  const [isToggled, toggle] = useState(toggled);

  const callback = () => {
    toggle(!isToggled);
    onClick(!isToggled);
  };

  return (
    <label className={"toggle-label " + (className ? className : "")}>
      <span className="toggle-text">{label}</span>
      <input
        className={"toggle-input "}
        type="checkbox"
        defaultChecked={isToggled}
        onClick={callback}
      />
      <span className="toggle-span" />
    </label>
  );
};

export default Toggle;
