import api from "./api";

const getAllUsers = () => {
  return api.instance.get("/api/user/all");
};

const getUser = () => {
  return api.instance.get("/api/user");
};

const getUserById = (id) => {
  return api.instance.get(`/api/user/${id}`);
};

const updateUser = (id, username, password) => {
  let data = { username: username.toLowerCase() };
  if (password) {
    data.password = password;
  }
  return api.instance.put(`/api/user/${id}`, data);
};

const deleteUser = (id) => {
  return api.instance.delete(`/api/user/${id}`);
};

const UserService = {
  getAllUsers,
  getUser,
  getUserById,
  updateUser,
  deleteUser,
};

export default UserService;
