import React from "react";
import { Routes, Route } from "react-router-dom";
import Form from "./Mold.Form";
import Overview from "./Mold.Overview";
import View from "./Mold.View";

const Mold = (props) => {
  return (
    <Routes>
      <Route path="/" element={<Overview isAdmin={props.isAdmin} />} />
      <Route path="/view/:id" element={<View isAdmin={props.isAdmin} />} />
      <Route path="/form" element={<Form />} />
      <Route path="/form/:id" element={<Form />} />
    </Routes>
  );
};

export default Mold;
