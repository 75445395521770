import React from "react";

const TextInput = (props) => {
  const { error, refs, ...inputProps } = props;
  return (
    <>
      <div className="input-error">{error && <span>{error}</span>}</div>
      <input
        {...inputProps}
        className={
          "mb-2 input-text " +
          (inputProps.className ? inputProps.className : "")
        }
        ref={refs}
      />
    </>
  );
};

export default TextInput;
