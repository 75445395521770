import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import MaterialService from "../../services/material.service";
import Button from "../Form/Button";
import BlockHeader from "../Common/BlockHeader";
import Block from "../Common/Block";
import Table from "../Common/Table.js/Table";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import Functions from "../../common/Functions";
import DeleteModal from "../Common/DeleteModal";

const Overview = () => {
  const [materials, setMaterials] = useState();
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    MaterialService.getAllMaterials().then((response) => {
      setMaterials(response.data);
      setLoading(false);
    });
  }, []);

  const columns = useMemo(
    () => [
      {
        header: "Naam",
        accessor: "name",
        width: "auto",
      },
      {
        header: "Eenheid",
        accessor: "unit",
        width: "auto",
      },
      {
        header: "Prijs",
        accessor: "price",
        width: "auto",
      },
      {
        header: "",
        accessor: "menu",
        width: "40",
        notClickable: true,
      },
    ],
    []
  );

  const onRowClick = (row) => {
    navigate(`/material/form/${row.id}`);
  };

  const onDelete = (id) => {
    MaterialService.deleteMaterial(id).then((response) => {
      setMaterials(materials.filter((material) => material.id !== id));
      toggleDeleteModal();
    });
  };

  const renderTableValue = (row, column) => {
    if (column.accessor === "menu") {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <FontAwesomeIcon
            icon={faTrashAlt}
            className={"fs-20 color-light-red cursor-pointer"}
            onClick={() => toggleDeleteModal(row.id)}
          />
        </div>
      );
    }

    if (column.accessor === "price") {
      return (
        <span>
          {row[column.accessor]
            ? Functions.euroFormat(row[column.accessor])
            : ""}
        </span>
      );
    }
    return <span>{row[column.accessor]}</span>;
  };

  const buttons = [
    <Button text={"Aanmaken"} onClick={() => navigate("/material/form")} />,
  ];

  const toggleDeleteModal = (id) => {
    setShowDeleteModal(!showDeleteModal);
    setDeleteId(id);
  };

  return (
    <div className="col-12 p-0 px-md-3">
      <DeleteModal
        show={showDeleteModal}
        title={"Materiaal verwijderen"}
        onSubmit={() => onDelete(deleteId)}
        onClose={toggleDeleteModal}
      >
        Weet je zeker dat je dit materiaal wilt verwijderen?
      </DeleteModal>
      <Block>
        <BlockHeader buttons={buttons}>Materialen</BlockHeader>
        <Table
          columns={columns}
          data={
            materials
              ? materials.sort((a, b) => a.name.localeCompare(b.name))
              : []
          }
          renderTableValue={renderTableValue}
          onRowClick={onRowClick}
          loading={loading}
        />
      </Block>
    </div>
  );
};

export default Overview;
