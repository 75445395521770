import React from "react";

const BlockHeader = (props) => {
  return (
    <div className="d-flex flex-column-reverse flex-md-row row align-items-centers mb-4">
      <div className="col-12 col-md-3 d-flex justify-content-center justify-content-md-start mt-3 mt-md-0">{props.buttonsLeft
        ? props.buttonsLeft.map((button, index) => {
          return (
            <span key={"block-header-button-left-" + index}>{button}</span>
          );
        })
        : ""}</div>
      <div className="col-12 col-md-6">
        <h2 className=" mt-3 mb-0 mt-md-0 text-center">
          {props.children ? props.children : ""}
        </h2>
      </div>
      <div className=" col-12 col-md-3">
        <div className="d-flex align-items-center justify-content-end">
          {props.buttons
            ? props.buttons.map((button, index) => {
              return (
                <span key={"block-header-button-" + index}>{button}</span>
              );
            })
            : ""}
        </div>
      </div>
    </div>
  );
};

export default BlockHeader;
