import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import logo from "../images/logo.png";

import "./Login.css";

import AuthService from "../services/auth.service";
import Form from "./Form/Form";
import Block from "./Common/Block";

const Login = () => {
  let navigate = useNavigate();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleLogin = (data) => {
    setLoading(true);

    AuthService.login(data.username, data.password).then(
      () => {
        navigate("/");
        window.location.reload();
      },
      (error) => {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
          setLoading(false);
        }
      }
    );
  };

  const fromInputs = [
    {
      placeholder: "Gebruikersnaam",
      error: errors.username && "Dit veld is verplicht",
      type: "text",
      ...register("username", { required: true }),
    },
    {
      placeholder: "Wachtwoord",
      error: errors.password && "Dit veld is verplicht",
      type: "password",
      ...register("password", { required: true }),
    },
  ];

  return (
    <div className="h-100 d-flex flex-column align-items-center justify-content-center">
      <div className="mb-3">
        <img src={logo} className={"logo"} alt={"logo"} />
      </div>
      <Block className={"p-5"}>
        <h2 className="d-flex justify-content-center mb-3">Inloggen</h2>
        <Form
          fromInputs={fromInputs}
          onSubmit={handleSubmit(handleLogin)}
          submitText={"Inloggen"}
          error={error}
          loading={loading}
        />
      </Block>
    </div>
  );
};

export default Login;
