import api from "./api";
import moment from "moment";

const getAllMolds = () => {
  return api.instance.get("/api/mold");
};

const getMold = (id) => {
  return api.instance.get(`/api/mold/${id}`);
};

const addMold = (name, description, moldNumber) => {
  return api.instance.post("/api/mold", { name, description, moldNumber });
};

const updateMold = (id, data) => {
  if (data.date) {
    data.date = moment(data.date).toDate();
  }

  return api.instance.put(`/api/mold/${id}`, data);
};

const deleteMold = (id) => {
  return api.instance.delete(`/api/mold/${id}`);
};

const MoldService = {
  getAllMolds,
  getMold,
  addMold,
  updateMold,
  deleteMold,
};

export default MoldService;
