import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import Form from "../Form/Form";
import Block from "../Common/Block";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../Form/Button";
import BlockHeader from "../Common/BlockHeader";
import MoldService from "../../services/mold.service";
import moment from "moment";

const MoldForm = () => {
  const [error, setError] = useState();
  const [succes, setSucces] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();

  let yupSchema = {
    name: Yup.string().required("Naam is verplicht"),
    description: Yup.string().required("Omschrijving is verplicht"),
    moldNumber: Yup.string().required("Malnummer is verplicht"),
  };

  if (id) {
    yupSchema.date = Yup.date().required("Datum is verplicht");
  }

  const formSchema = Yup.object().shape(yupSchema);

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  const fromInputs = [
    {
      placeholder: "Naam",
      error: errors.name?.message,
      type: "text",
      ...register("name"),
    },
    {
      placeholder: "Omschrijving",
      error: errors.description?.message,
      type: "text",
      ...register("description"),
    },
    {
      placeholder: "Malnummer",
      error: errors.moldNumber?.message,
      type: "text",
      ...register("moldNumber"),
    },
  ];

  if (id) {
    fromInputs.push({
      placeholder: "Datum",
      error: errors.date?.message,
      type: "date",
      ...register("date"),
    });
  }

  const handleAdd = (data) => {
    setLoading(true);

    MoldService.addMold(data.name, data.description, data.moldNumber).then(
      (response) => {
        if (response.status === 200) {
          setSucces("Mal is toegevoegd");
          setError();
          setLoading(false);
          reset();
        }
      },
      (error) => {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
          setSucces();
          setLoading(false);
        }
      }
    );
  };

  const handleUpdate = (data) => {
    setLoading(true);

    MoldService.updateMold(id, data).then(
      (response) => {
        if (response.status === 200) {
          navigate(-1);
        }
      },
      (error) => {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
          setSucces();
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    if (id) {
      MoldService.getMold(id).then((response) => {
        if (response.data) {
          if (response.data.date) {
            response.data.date = moment(response.data.date).format(
              "YYYY-MM-DD"
            );
          }

          reset(response.data);
        }
      });
    }
  }, [id, reset]);

  const buttons = [
    <Button text={"Terug"} secondary={true} onClick={() => navigate(-1)} />,
  ];

  return (
    <div className="col-md-12">
      <Block>
        <BlockHeader buttons={buttons}>
          Mal {id ? "aanpassen" : "toevoegen"}
        </BlockHeader>
        <Form
          fromInputs={fromInputs}
          submitText={id ? "Aanpassen" : "Toevoegen"}
          loading={loading}
          onSubmit={handleSubmit(id ? handleUpdate : handleAdd)}
          error={error}
          succes={succes}
        />
      </Block>
    </div>
  );
};

export default MoldForm;
