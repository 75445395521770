import React from "react";

const Block = (props) => {
  return (
    <div
      className={
        "bg-white p-4 shadow radius-10 " +
        (props.className ? props.className : "")
      }
    >
      {props.children}
    </div>
  );
};

export default Block;
