import api from "./api.ts";
import TokenService from "./token.service";

const register = (username, password) => {
  return api.instance.post("/api/auth/signup", {
    username: username.toLowerCase(),
    password,
    role: ["user"],
  });
};

const login = (username, password) => {
  return api.instance
    .post("/api/auth/signin", {
      username: username.toLowerCase(),
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        TokenService.setUser(response.data);
      }

      return response.data;
    });
};

const logout = () => {
  TokenService.removeUser();
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
};

export default AuthService;
