import React, { useState, useEffect } from "react";
import { Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowRightFromBracket,
  faHammer,
  faPencilRuler,
  faTable,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import logo from "./images/logo.png";
import moment from 'moment'
import 'moment/locale/nl'

import AuthService from "./services/auth.service";

import Login from "./components/Login";
import Profile from "./components/Profile";

import EventBus from "./common/EventBus";
import User from "./components/User/User";
import Material from "./components/Materials/Material";
import Mold from "./components/Mold/Mold";
import Data from "./components/data/Data";

const App = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);
  const navigate = useNavigate();

  const logOut = () => {
    AuthService.logout();
    setCurrentUser(undefined);
    setIsAdmin(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    moment.locale('nl')

    if (user) {
      setCurrentUser(user);
      setIsAdmin(user.roles.includes("ROLE_ADMIN"));
    }

    EventBus.on("logout", () => {
      logOut();
      navigate("/login");
    });

    return () => {
      EventBus.remove("logout");
    };
    //eslint-disable-next-line
  }, []);

  return (
    <div className="app-container">
      {!currentUser ? (
        <Login />
      ) : (
        <div>
          <nav className="navbar navbar-expand navbar-light bg-white">
            <Link to={"/"} className="navbar-brand">
              <img src={logo} alt="logo" className="nav-logo" />
            </Link>
            <div className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link
                  to={"/mold"}
                  className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                >
                  <FontAwesomeIcon
                    icon={faPencilRuler}
                    className={"fs-30 color-light-red mb-1"}
                  />
                  <span className="fs-14">Mallen</span>
                </Link>
              </li>
              {isAdmin && (
                <>
                  <li className="nav-item">
                    <Link
                      to={"/material"}
                      className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon
                        icon={faHammer}
                        className={"fs-30 color-light-red mb-1"}
                      />
                      <span className="fs-14">Materialen</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/user"}
                      className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        className={"fs-30 color-light-red mb-1"}
                      />
                      <span className="fs-14">Medewerkers</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to={"/data"}
                      className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                    >
                      <FontAwesomeIcon
                        icon={faTable}
                        className={"fs-30 color-light-red mb-1"}
                      />
                      <span className="fs-14">Data tabellen</span>
                    </Link>
                  </li>
                </>
              )}
            </div>

            <div className="navbar-nav ml-auto ">
              <li className="nav-item">
                <Link
                  to={"/"}
                  className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                >
                  <FontAwesomeIcon
                    icon={faUser}
                    className={"fs-30 color-light-red mb-1"}
                  />
                  <span className="fs-14 first-uppercase">
                    {currentUser.username}
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <a
                  href="/"
                  className="nav-link color-black d-flex flex-column align-items-center justify-content-center"
                  onClick={logOut}
                >
                  <FontAwesomeIcon
                    icon={faArrowRightFromBracket}
                    className={"fs-30 color-light-red mb-1"}
                  />
                  <span className="fs-14">Uitloggen</span>
                </a>
              </li>
            </div>
          </nav>

          <div className="container mt-3">
            <Routes>
              <Route path="/" element={<Profile />} />
              <Route path="/user/*" element={<User />} />
              <Route path="/material/*" element={<Material />} />
              <Route path="/mold/*" element={<Mold isAdmin={isAdmin} />} />
              <Route path="/data/*" element={<Data />} />
            </Routes>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
