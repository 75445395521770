import axios from "axios";
import React, { useEffect, useState } from "react";
import AuthService from "../services/auth.service";
import Block from "./Common/Block";

const Profile = () => {
  const currentUser = AuthService.getCurrentUser();
  const [weather, setWeather] = useState();

  useEffect(() => {
    // wheater api
    axios
      .get(
        "https://api.openweathermap.org/data/2.5/weather?q=Bunschoten-Spakenburg&appid=d4c2f0e70f38621843f4975a0e7a6279&units=metric&lang=nl"
      )
      .then((response) => {
        setWeather(response.data);
      });
  }, []);

  return (
    <div className="col-12">
      <Block>
        <h2 className="mb-3 text-center">
          Welkom,{" "}
          <span className="first-uppercase"> {currentUser.username}</span>!
        </h2>
        {weather && (
          <div>
            <h5>Het weer vandaag in Bunschoten-Spakenburg:</h5>
            <div className="row">
              <div className="col col-auto">
                <div>Temperatuur:</div>
                <div>Min:</div>
                <div>Max:</div>
              </div>
              <div className="col">
                <div>
                  {Math.round(weather.main.temp)}&#8451;{" "}
                  {weather.weather[0].description}
                </div>
                <div>{Math.round(weather.main.temp_min)} &#8451;</div>
                <div>{Math.round(weather.main.temp_max)} &#8451;</div>
              </div>
            </div>
          </div>
        )}
      </Block>
    </div>
  );
};

export default Profile;
