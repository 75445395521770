import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Block from "../Common/Block";
import BlockHeader from "../Common/BlockHeader";
import Button from "../Form/Button";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Toggle from "../Common/Toggle";
import api from "../../services/api";
import { MoldDTO } from "../../typescript/api/api";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";

type Props = { isAdmin: boolean };

const Overview = (props: Props) => {
  const [molds, setMolds] = useState<MoldDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterFinished, setFilterFinished] = useState<boolean>(false);
  const [showFinishedModal, setShowFinishedModal] = useState<boolean>(false);
  const [finishedMold, setFinishedMold] = useState<MoldDTO | undefined>();
  const navigate = useNavigate();

  const getAllMolds = useCallback((finished: boolean) => {
    setLoading(true);
    api.api.getAllMolds({ finished: finished }).then((response) => {
      setMolds(response.data);

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    getAllMolds(filterFinished);
  }, [filterFinished, getAllMolds]);

  const onRowClick: GridEventListener<"rowClick"> = (params) => {
    navigate(`/mold/view/${params.id}`);
  };

  const columns: GridColDef[] = [
    { field: "name", headerName: "Naam", width: 200 },
    { field: "description", headerName: "Omschrijving", width: 200 },
    {
      field: "date",
      headerName: "Datum",
      width: 150,
      valueFormatter: (params) => moment(params.value).format("DD-MM-YYYY"),
    },
    { field: "moldNumber", headerName: "Malnummer", width: 150 },
    {
      field: "finished",
      headerName: "",
      width: 20,
      renderCell: (params) => (
        <FontAwesomeIcon
          icon={faCheck}
          className={
            "cursor-pointer " +
            (params.value ? "color-light-green" : "color-grey")
          }
          onClick={(event) => {
            event.stopPropagation();
            toggleFinishedModal(params.row);
          }}
        />
      ),
    },
  ];

  const handleToggle = (toggled: boolean) => {
    setFilterFinished(toggled);
  };

  const buttons = [
    <Toggle
      label="Klaar"
      toggled={false}
      onClick={handleToggle}
      className={"mt-3 mr-3"}
    />,
  ];

  if (props.isAdmin)
    buttons.push(
      <Button text={"Aanmaken"} onClick={() => navigate("/mold/form")} />
    );

  const toggleFinishedModal = (mold?: MoldDTO) => {
    setShowFinishedModal(!showFinishedModal);
    setFinishedMold(mold);
  };

  const handleFinished = (mold: MoldDTO | undefined) => {
    if (mold !== undefined) {
      mold.finished = !mold.finished;
      api.api.updateMold(mold.id, mold).then((response) => {
        if (response.status === 200) {
          getAllMolds(filterFinished);

          toggleFinishedModal();
        }
      });
    }
  };

  const paginationModel = { page: 0, pageSize: 25 };

  return (
    <div className="col-12 p-0 px-md-3">
      {showFinishedModal && (
        <FinishedModal
          onClose={toggleFinishedModal}
          handleFinished={handleFinished}
          mold={finishedMold}
        />
      )}
      <Block>
        <BlockHeader buttons={buttons}>Mallen</BlockHeader>
        <DataGrid
          rows={molds}
          columns={columns}
          initialState={{ pagination: { paginationModel } }}
          loading={loading}
          onRowClick={onRowClick}
        />
      </Block>
    </div>
  );
};

type PropsFinishedModal = {
  handleFinished: (mold: MoldDTO | undefined) => void;
  mold: MoldDTO | undefined;
  onClose: (mold: MoldDTO) => void;
};

const FinishedModal = (props: PropsFinishedModal) => {
  return (
    <div className="modal">
      <Block>
        <div className="d-flex justify-content-end">
          <Button className={"mb-3"} text={"Sluiten"} onClick={props.onClose} />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center px-5">
          <h4 className="mb-3">Mal klaar</h4>
          <span className="mb-3">Is de mal klaar?</span>
          <div className="d-flex">
            <Button
              className={"mr-2"}
              secondary
              text={"Nee"}
              onClick={() => props.handleFinished(props.mold)}
            />
            <Button
              text={"Ja"}
              onClick={() => props.handleFinished(props.mold)}
            />
          </div>
        </div>
      </Block>
    </div>
  );
};

export default Overview;
