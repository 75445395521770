import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Button = (props) => {
  const { loading, secondary, ...buttonProps } = props;
  return (
    <button
      {...buttonProps}
      className={
        "button" +
        (secondary ? "-secondary" : "") +
        " d-flex align-items-center " +
        (buttonProps.className ? buttonProps.className : "")
      }
    >
      {loading && (
        <span className="mr-1 d-flex align-items-center">
          <ClipLoader size={16} color={"#fff"} />
        </span>
      )}
      {buttonProps.text}
    </button>
  );
};

export default Button;
