import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import AuthService from "../../services/auth.service";
import Form from "../Form/Form";
import Block from "../Common/Block";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "../../services/user.service";
import Button from "../Form/Button";
import BlockHeader from "../Common/BlockHeader";

const UserFrom = () => {
  const [error, setError] = useState();
  const [succes, setSucces] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let { id } = useParams();

  let yupShape = {
    username: Yup.string().required("Gebruikersnaam is verplicht"),
    password: Yup.string()
      .required("Wachtwoord is verplicht")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
        "Moet minimaal 8 tekens lang zijn, Een hoofdletter, Een kleine letter en een nummer bevatten"
      ),
    cpassword: Yup.string()
      .required("Wachtwoord is verplicht")
      .oneOf([Yup.ref("password")], "Wachtwoorden komen niet overeen"),
  };

  if (id) {
    yupShape = {
      username: Yup.string().required("Gebruikersnaam is verplicht"),
    };
  }

  const formSchema = Yup.object().shape(yupShape);
  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(formOptions);

  const fromInputs = [
    {
      placeholder: "Gebruikersnaam",
      error: errors.username && errors.username.message,
      type: "text",
      ...register("username"),
    },
    {
      placeholder: "Wachtwoord",
      error: errors.password && errors.password.message,
      type: "password",
      ...register("password"),
    },
    {
      placeholder: "Wachtwoord bevestigen",
      error: errors.cpassword && errors.cpassword.message,
      type: "password",
      ...register("cpassword"),
    },
  ];

  const handleRegister = (data) => {
    setLoading(true);

    AuthService.register(data.username, data.password).then(
      (response) => {
        if (response?.data?.message) {
          setSucces(response.data.message);
          setLoading(false);
          reset();
          setError();
        }
      },
      (error) => {
        if (error?.response?.data?.message) {
          setError(error.response.data.message);
          setSucces();
          setLoading(false);
        }
      }
    );
  };

  const handleUpdate = (data) => {
    setLoading(true);

    UserService.updateUser(id, data.username, data.password).then(
      (response) => {
        navigate(-1);
      },
      (error) => {
        setError(error.response.data.message);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (id) {
      UserService.getUserById(id).then(
        (response) => {
          if (response?.data?.username) {
            reset({
              username: response.data.username,
            });
          }
        },
        (error) => {
          if (error?.response?.data?.message) {
            setError(error.response.data.message);
            setSucces();
            setLoading(false);
          }
        }
      );
    }
  }, [id, reset]);

  const buttons = [
    <Button text={"Terug"} secondary={true} onClick={() => navigate(-1)} />,
  ];

  return (
    <div className="col-md-12">
      <Block>
        <BlockHeader buttons={buttons}>
          Medewerker {id ? "aanpassen" : "toevoegen"}
        </BlockHeader>
        <Form
          fromInputs={fromInputs}
          submitText={id ? "Aanpassen" : "Toevoegen"}
          loading={loading}
          onSubmit={handleSubmit(id ? handleUpdate : handleRegister)}
          error={error}
          succes={succes}
        />
      </Block>
    </div>
  );
};

export default UserFrom;
