import api from "./api";

const getMoldHourById = (id) => {
  return api.instance.get("/api/moldhour/" + id);
};

const addMoldHour = (moldId, hours, date) => {
  return api.instance.post("/api/mold/" + moldId + "/moldhour", {
    hours,
    date,
  });
};

const updateMoldHour = (id, hours) => {
  return api.instance.put("/api/moldhour/" + id, {
    hours,
  });
};

const deleteMoldHour = (id) => {
  return api.instance.delete(`/api/moldhour/${id}`);
};

const MoldHourService = {
  getMoldHourById,
  addMoldHour,
  updateMoldHour,
  deleteMoldHour,
};

export default MoldHourService;
