import React from "react";
import { Routes, Route } from "react-router-dom";
import Form from "./Material.Form";
import Overview from "./Material.Overview";

const Material = () => {
  return (
    <Routes>
      <Route path="/" element={<Overview />} />
      <Route path="/form" element={<Form />} />
      <Route path="/form/:id" element={<Form />} />
    </Routes>
  );
};

export default Material;
